.font-size-label {
  font-size: 1.04em !important;
}

.border-radius {
  border-radius: 10px !important;
}

.curso-pointer {
  cursor: pointer !important;
}

.font-size-badge {
  font-size: 13px;
}

.ul-in-line li {
  display: inline;
  margin: 6px;
  cursor: pointer;
}

.ul-li-active {
  border: 1px solid #4d5ba0;
  border-radius: 16px;
  padding: 5px;
}

.inputs-date {
  display: flex;
  justify-content: flex-end;
}

.input-date-payments:first-child {
  margin-right: 2%;
  width: 200px;
}

.regional-summary-external {
  margin-top: 150px !important;
}

/* Ambassador ================================================================== */
.status-ambassador-active {
  font-weight: 700;
  color: #1aae6f;
  background-color: rgba(147, 231, 195, 0.5);
  padding: 2px 8px;
  border-radius: 8px;
}

.status-ambassador-canceled {
  font-weight: 700;
  color: #f80031;
  background-color: rgba(251, 175, 190, 0.5);
  padding: 2px 8px;
  border-radius: 8px;
}

.status-ambassador-to-canceled {
  font-weight: 700;
  color: #ff3709;
  background-color: rgba(254, 201, 189, 0.5);
  padding: 2px 8px;
  border-radius: 8px;
}

.container-buttons-ambassador {
  display: flex;
  padding: 10px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.button-ambassador {
  height: 34px;
  padding: 0px 8px;
  border-radius: 6px;
  border: 1px solid #17668e;
  background: none;
  color: #17668e;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s;
  margin: 4px;
}

.button-ambassador:hover {
  background: #17668e;
  color: #ffffff;
}

.container-set-ambassador {
  display: flex;
  width: 100%;
  padding: 40px;
}

.body-set-ambassador {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
}

.select-ambassador {
  cursor: pointer;
  margin: 8px 0;
}

.container-new-ambassador {
  background-color: #f3f3f3;
  width: 90%;
  border-radius: 8px;
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.body-new-ambassador {
  width: 90%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.image-new-ambassador {
  background-color: #d1d1d1;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 8px;
}

.remove-new-ambassador {
  background-color: rgba(209, 36, 70, 0.7);
  width: 10%;
  margin: -8px;
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.remove-new-ambassador:hover {
  background-color: rgba(209, 36, 70, 1);
}

.submit-ambassador {
  padding: 8px;
}

/* Regional ================================================================== */
.status-regional-active {
  font-weight: 700;
  color: #1aae6f;
  background-color: rgba(147, 231, 195, 0.5);
  padding: 2px 8px;
  border-radius: 8px;
}

.status-regional-canceled {
  font-weight: 700;
  color: #f80031;
  background-color: rgba(251, 175, 190, 0.5);
  padding: 2px 8px;
  border-radius: 8px;
}

.status-regional-to-canceled {
  font-weight: 700;
  color: #ff3709;
  background-color: rgba(254, 201, 189, 0.5);
  padding: 2px 8px;
  border-radius: 8px;
}

.container-buttons-regional {
  display: flex;
  padding: 10px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.button-regional {
  height: 34px;
  padding: 0px 8px;
  border-radius: 6px;
  border: 1px solid #17668e;
  background: none;
  color: #17668e;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s;
  margin: 4px;
}

.button-regional:hover {
  background: #17668e;
  color: #ffffff;
}

/* .container-set-regional {
  display: flex;
  width: 100%;
  padding: 40px;
} */

.body-set-regional {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
}

.select-regional {
  cursor: pointer;
  margin: 8px 0;
}

.container-new-regional {
  background-color: #f3f3f3;
  width: 90%;
  border-radius: 8px;
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.body-new-regional {
  width: 90%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.image-new-regional {
  background-color: #d1d1d1;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 8px;
}

.remove-new-regional {
  background-color: rgba(209, 36, 70, 0.7);
  width: 10%;
  margin: -8px;
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.remove-new-regional:hover {
  background-color: rgba(209, 36, 70, 1);
}

.submit-regional {
  padding: 8px;
}

/* Regional ==================================================================== */

.status-regional-active {
  font-weight: 700;
  color: #1aae6f;
  background-color: rgba(147, 231, 195, 0.5);
  padding: 2px 8px;
  border-radius: 8px;
}

.status-regional-canceled {
  font-weight: 700;
  color: #f80031;
  background-color: rgba(251, 175, 190, 0.5);
  padding: 2px 8px;
  border-radius: 8px;
}

.status-regional-to-canceled {
  font-weight: 700;
  color: #ff3709;
  background-color: rgba(254, 201, 189, 0.5);
  padding: 2px 8px;
  border-radius: 8px;
}

.container-buttons-regional {
  display: flex;
  padding: 10px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.button-regional {
  height: 34px;
  padding: 0px 8px;
  border-radius: 6px;
  border: 1px solid #17668e;
  background: none;
  color: #17668e;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s;
  margin: 4px;
}

.button-regional:hover {
  background: #17668e;
  color: #ffffff;
}

.container-set-regional {
  /* display: flex; */
  /* width: 100%; */
  padding: 20px;
}

.body-set-regional {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
}

.select-regional {
  cursor: pointer;
  margin: 8px 0;
}

.container-new-regional {
  background-color: #f3f3f3;
  width: 90%;
  border-radius: 8px;
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.body-new-regional {
  width: 90%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.image-new-regional {
  background-color: #d1d1d1;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 8px;
}

.remove-new-regional {
  background-color: rgba(209, 36, 70, 0.7);
  width: 10%;
  margin: -8px;
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.remove-new-regional:hover {
  background-color: rgba(209, 36, 70, 1);
}

.submit-regional {
  padding: 8px;
}

.form-input-invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
}

/* Fim Regional ================================================================ */

@media (max-width: 1000px) {
  .d-flex {
    display: flex !important;
  }

  .auth-user {
    margin-left: 60%;
  }

  .ul-in-line li {
    margin: 4px;
  }
}

@media (max-width: 400px) {
  .d-flex {
    display: block !important;
  }

  .auth-user {
    margin-left: 50%;
  }

  .ul-in-line li {
    margin: 0 4px 0px 0px;
  }
}
